import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Message from './Message';

export default class ErrorHandler extends Component {
  static metaData() {
    return [
      <title>Something went wrong</title>,
      <meta name="description" content="Something went wrong :(" />,
    ];
  }

  render() {
    return (
      <Message status={this.props.status} message={this.props.message} siteSection={this.props.siteSection} />
    );
  }
}

export const ERROR_PROP_TYPES = {
  status: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  message: PropTypes.string,
  siteSection: PropTypes.string,
};

ErrorHandler.propTypes = ERROR_PROP_TYPES;
