import PropTypes from 'prop-types';
import {
  PHONE_PROP_TYPES,
  DISPLAY_ADDRESS_PROP_TYPES,
  BRANDING_PROP_TYPES,
  PRICE_PROP_TYPES,
  AGENCY_ADDRESS_PROP_TYPES,
} from '../../routes/detail/model/types';
import { PRODUCT_TYPES } from './constants/depthProducts';
import { TENURE_TYPE_OBJECT_PROP_TYPES } from './constants/tenureType';
import { channelObjects } from './constants/channels';

const { ForSale, ForLease, Sold, Leased, Invest } = channelObjects;

export const AGENT_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  phone: PHONE_PROP_TYPES,
  imagePath: PropTypes.string,
});

export const AGENCY_BRANDING_PROP_TYPES = PropTypes.shape({
  alt: PropTypes.string.isRequired,
  brandingColor: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  logoSize: PropTypes.string.isRequired,
  logoUrlTemplate: PropTypes.string.isRequired,
});

export const AGENCY_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  additionalBranding: PropTypes.bool,
  address: AGENCY_ADDRESS_PROP_TYPES,
  phone: PHONE_PROP_TYPES,
  branding: BRANDING_PROP_TYPES,
  salespeople: PropTypes.arrayOf(AGENT_PROP_TYPES),
});

export const AGENCIES_PROP_TYPES = PropTypes.arrayOf(AGENCY_PROP_TYPES);

export const CHANNEL_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  omniture: PropTypes.string.isRequired,
  krux: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  adArea: PropTypes.string,
  shortHumanReadable: PropTypes.string.isRequired,
  displayInSelect: PropTypes.string.isRequired,
  humanReadable: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tealium: PropTypes.string.isRequired,
});

export const SELECTED_LISTING_PROP_TYPES = PropTypes.shape({
  product: PRODUCT_TYPES.isRequired,
  address: PropTypes.shape({
    streetAddress: PropTypes.string.isRequired,
    suburbAddress: PropTypes.string.isRequired,
  }).isRequired,
  agencies: AGENCIES_PROP_TYPES,
  listingId: PropTypes.string.isRequired,
  currentChannel: CHANNEL_PROP_TYPES.isRequired,
  channelTenureType: PropTypes.string,
  tenureTypeObject: TENURE_TYPE_OBJECT_PROP_TYPES,
});

const SHARED_LISTING_PROP_TYPES = {
  agencies: AGENCIES_PROP_TYPES,
  omniture: PropTypes.shape({
    agencyIds: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    imageCarousel: PropTypes.shape({
      agencyIds: PropTypes.string.isRequired,
      channel: PropTypes.string.isRequired,
    }),
  }),
  tenureTypeObject: TENURE_TYPE_OBJECT_PROP_TYPES,
  id: PropTypes.string,
  details: PropTypes.shape({
    price: PropTypes.string.isRequired,
    streetAddress: PropTypes.string.isRequired,
    suburbAddress: PropTypes.string.isRequired,
  }),
  pdpUrl: PropTypes.string,
  conjunctionalAgencies: AGENCIES_PROP_TYPES,
  otherAgencies: AGENCIES_PROP_TYPES,
  branding: BRANDING_PROP_TYPES,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ).isRequired,
  product: PropTypes.string.isRequired,
};

export const EXACT_LISTING_PROP_TYPES = PropTypes.shape({
  ...SHARED_LISTING_PROP_TYPES,
  conjunctionalAgencies: PropTypes.arrayOf(AGENCY_BRANDING_PROP_TYPES),
  otherAgencies: PropTypes.arrayOf(PropTypes.string),
});

export const LISTING_PROP_TYPES = PropTypes.shape(SHARED_LISTING_PROP_TYPES);

export const PROPERTY_TYPE_OBJECT_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  oldUrl: PropTypes.string.isRequired,
  marketing: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
  longDisplayText: PropTypes.string.isRequired,
  sentenceDisplayText: PropTypes.string.isRequired,
  pdpTitle: PropTypes.string.isRequired,
});

export const PRODUCT_TYPE_STRINGS = PropTypes.oneOf([
  'buildingprofile',
  'eliteplus',
  'elite',
  'enhanced',
  'basic',
  'unknown',
]);

export const PHOTO_PROP_TYPES = PropTypes.shape({
  alt: PropTypes.string.isRequired,
  urlTemplate: PropTypes.string.isRequired,
});

export const LOCATION_PROP_TYPES = PropTypes.shape({
  display: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const SIMILAR_PROPERTY_ITEM = PropTypes.shape({
  address: DISPLAY_ADDRESS_PROP_TYPES.isRequired,
  area: PropTypes.string,
  branding: BRANDING_PROP_TYPES.isRequired,
  id: PropTypes.string,
  mainPhoto: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  pdpUrl: PropTypes.string.isRequired,
  price: PRICE_PROP_TYPES.isRequired,
  product: PRODUCT_TYPES,
  propertyTypeObjects: PropTypes.arrayOf(PROPERTY_TYPE_OBJECT_PROP_TYPES).isRequired,
  title: PropTypes.string,
});

export const USER_AGENT_PROP_TYPES = PropTypes.shape({
  isiPad: PropTypes.bool,
  isiPod: PropTypes.bool,
  isAndroid: PropTypes.bool,
  isiPhone: PropTypes.bool,
});

export const NATIVE_AD_PROP_TYPES = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  channel: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  proptype: PropTypes.string.isRequired,
  suburb: PropTypes.string,
  postcode: PropTypes.string,
  region: PropTypes.string,
  env: PropTypes.string,
  pos: PropTypes.string.isRequired,
  platform: PropTypes.string,
  sect: PropTypes.string.isRequired,
  advertorial: PropTypes.shape({
    createAd: PropTypes.func.isRequired,
    refreshView: PropTypes.func.isRequired,
  }),
  adLoadedCallback: PropTypes.func,
  configSite: PropTypes.string,
  configSize: PropTypes.string,
};

export const ADVERTISING_PROP_TYPES = PropTypes.shape({
  channel: PropTypes.oneOf([
    ForLease.adArea,
    ForSale.adArea,
    Sold.adArea,
    Leased.adArea,
    Invest.adArea,
  ]).isRequired,
  state: PropTypes.string.isRequired,
  proptype: PropTypes.string.isRequired,
  region: PropTypes.string,
  suburb: PropTypes.string,
  postcode: PropTypes.string,
  env: PropTypes.string,
});

export const ENQUIRY_OPTIONS_TYPES = PropTypes.arrayOf(PropTypes.string);

export const BREADCRUMB_ITEMS_PROP_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
);

export const CLUSTER_PROP_TYPE = PropTypes.shape({
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listingIds: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const HEADER_PROP_TYPE = {
  pathname: PropTypes.string,
  isSignedIn: PropTypes.bool,
  theme: PropTypes.string,
  navigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      internal: PropTypes.bool,
    }),
  ),
  isIE: PropTypes.bool,
  search: PropTypes.string,
};

export const SURROUNDING_SUBURB_TITLE_PROP_TYPE = PropTypes.shape({
  context: PropTypes.string,
  title: PropTypes.string.isRequired,
});
