import get from 'lodash/get';
import pick from 'lodash/pick';
import difference from 'lodash/difference';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { logError } from './log';
import { TEALIUM_ENV } from './tracking/tealium/constants';
import { isClient } from '../routes/clientOnly';

export const DISABLE_QUALTRICS = 'disableQualtrics';

export const MARKET_TRENDS_SLICE3 = 'marketTrendsSlice3';

export const REQUIRED_CONFIG_PROPS = [
  'WEBSITE_ROOT',
  'MYCOMMERCIAL_UI_HOST',
  'LISTING_BFF_ENDPOINT',
  'LISTING_BFF_ORIGIN_ENDPOINT',
  'SMART_SUGGEST_ENDPOINT',
  'COMMIT',
  'GOOGLE_MAPS_CLIENT_ID',
  'GOOGLE_MAPS_CHANNEL',
  TEALIUM_ENV,
  'DISABLE_QUALTRICS',
  'RELEASES',
  'LOCKE_CLIENT_ID',
  'RELEASE_MARKETTRENDSSLICE3_PERCENTAGE',
];

export const SERVER_ONLY = [
  'QUALTRICS_ZONE_ID_2',
  'QUALTRICS_ZONE_PREFIX_2',
  'QUALTRICS_ZONE_ID_1',
  'QUALTRICS_ZONE_PREFIX_1',
  'FALLBACK_ASSETS_URL',
  TEALIUM_ENV,
  'LOCKE_CLIENT_ID',
];

const defaultDevConfig = {
  WEBSITE_ROOT: 'https://www.realcommercial.com.au',
  MYCOMMERCIAL_UI_HOST: 'https://ui-mycommercial.rca-prod.realestate.com.au',
  QUALTRICS_ZONE_ID_2: 'ZN_b15ZNqmEuhdK8ap',
  QUALTRICS_ZONE_PREFIX_2: 'znb15znqmeuhdk8ap-reagroup',
  QUALTRICS_ZONE_ID_1: 'ZN_3UGnR3XkPGz71k1',
  QUALTRICS_ZONE_PREFIX_1: 'zn3ugnr3xkpgz71k1-reagroup',
  LISTING_BFF_ENDPOINT: 'https://api.realcommercial.com.au',
  LISTING_BFF_ORIGIN_ENDPOINT: 'https://api.realcommercial.com.au',
  SMART_SUGGEST_ENDPOINT: 'https://suggest.realestate.com.au',
  FALLBACK_ASSETS_URL: 'https://static.rca-prod.realestate.com.au/listing-ui',
  GOOGLE_MAPS_CLIENT_ID: 'gme-nsp',
  GOOGLE_MAPS_CHANNEL: 'rca-dev-map-overlay',
  [TEALIUM_ENV]: 'dev',
  DISABLE_QUALTRICS: 'false',
  LOCKE_CLIENT_ID: '1p48kcl0ftem2iee52ih34034',
  MARKET_TRENDS_SLICE3: 'false',
};

const missingProps = (parsedConfig) =>
  difference([...REQUIRED_CONFIG_PROPS, ...SERVER_ONLY], keys(parsedConfig));

export const getIsomorphicUrl = (serverUrl, clientUrl) => {
  if (!serverUrl || !clientUrl) {
    logError(
      // eslint-disable-next-line max-len
      `getIsomorphicUrl requires server and client to be defined instead got server: ${serverUrl} and client: ${clientUrl}`,
    );
  }
  const usePublicEndpoint = isClient() || process.env.E2E_ENV;

  return usePublicEndpoint ? clientUrl : serverUrl;
};

const translateReleaseConfigFromEnv = (env) => {
  const RELEASE_PREFIX = 'RELEASE_';
  const translated = {};
  const releaseRegEx = new RegExp(`^${RELEASE_PREFIX}(?<releaseName>[a-z,A-Z]+)$`);

  Object.keys(env).forEach((name) => {
    if (releaseRegEx.test(name)) {
      const { releaseName } = releaseRegEx.exec(name).groups;

      translated[releaseName] = env[name] === 'true';
    }
  });

  return translated;
};

export const parseConfig = () => {
  if (isClient()) {
    const config = get(window, ['REA', 'config']);
    if (!config) {
      logError('REA.config is missing');
    }
    return pick(config, REQUIRED_CONFIG_PROPS);
  }
  const RELEASES = translateReleaseConfigFromEnv(process.env);
  const allConfigProps = pick({ ...process.env, RELEASES }, REQUIRED_CONFIG_PROPS, SERVER_ONLY);

  if (!isEmpty(missingProps(allConfigProps))) {
    if (process.env.NODE_ENV === 'production') {
      logError('config is missing the following required props', missingProps(allConfigProps));
      throw new Error('required props are missing, check that all environment variables are set');
    } else {
      merge(allConfigProps, pick(defaultDevConfig, missingProps(allConfigProps)));
    }
  }

  return allConfigProps;
};

export const prepareConfigForClient = (serverConfig) => pick(serverConfig, REQUIRED_CONFIG_PROPS);
