import { request, requestWithLogout } from './myRCAHttpService';
import bffRequest from '../../../shared/requests/bffRequest';

function myRcaHeaders(token) {
  return {
    'Content-Type': 'application/json',
    token,
  };
}

const searchEntrypoint = (searchConditionQuery) => ({
  path: 'searches',
  method: 'POST',
  body: searchConditionQuery,
  header: {
    'Content-Type': 'application/json',
  },
});

const mapSearchEntrypoint = (searchConditionQuery) => ({
  path: 'map-searches',
  method: 'POST',
  body: searchConditionQuery,
  header: {
    'Content-Type': 'application/json',
  },
});

const nearbyEntrypoint = (searchConditionQuery) => ({
  path: 'nearby-searches',
  method: 'POST',
  body: searchConditionQuery,
  header: {
    'Content-Type': 'application/json',
  },
});

const investEntrypoint = (searchConditionQuery) => ({
  path: 'invest/searches',
  method: 'POST',
  body: searchConditionQuery,
  header: {
    'Content-Type': 'application/json',
  },
});

const nearbyWidgetEntrypoint = (searchConditionQuery) => ({
  path: 'extended-searches',
  method: 'POST',
  body: searchConditionQuery,
  header: {
    'Content-Type': 'application/json',
  },
});

const savedListingsEntrypoint = (myRcaToken) => ({
  path: 'saved-listings',
  method: 'GET',
  header: myRcaHeaders(myRcaToken),
});

const getSavedSearchesEntryPoint = (myRcaToken, query) => ({
  path: 'saved-searches',
  method: 'POST',
  body: query,
  header: myRcaHeaders(myRcaToken),
});

const createEmailAlertEntryPoint = (myRcaToken, query) => ({
  path: 'email-alert',
  method: 'POST',
  body: query,
  header: myRcaHeaders(myRcaToken),
});

const getMarketInsightsEntrypoint = () => ({
  path: 'market-insights',
  method: 'GET',
});

const getMarketInsightsEntrypointMulti = (marketInsightsMultiSearchQuery) => ({
  path: 'market-insights',
  method: 'POST',
  body: marketInsightsMultiSearchQuery,
  header: {
    'Content-Type': 'application/json',
  },
});

export const searchListings = (searchConditionQuery, featureFlags) => {
  const endpoint = searchEntrypoint(searchConditionQuery);

  return bffRequest({ ...endpoint, query: { featureFlags } });
};

export const mapSearchListings = (searchConditionQuery, featureFlags) => {
  const endpoint = mapSearchEntrypoint(searchConditionQuery);

  return bffRequest({ ...endpoint, query: { featureFlags } });
};

export const nearbyListings = (searchConditionQuery, featureFlags) => {
  const endpoint = nearbyEntrypoint(searchConditionQuery);

  return bffRequest({ ...endpoint, query: { featureFlags } });
};

export const searchInvestListings = (searchConditionQuery, featureFlags) => {
  const endpoint = investEntrypoint(searchConditionQuery);

  return bffRequest({ ...endpoint, query: { featureFlags } });
};

export const extendedSearchListings = (searchConditionQuery, featureFlags) => {
  const endpoint = nearbyWidgetEntrypoint(searchConditionQuery);

  return bffRequest({ ...endpoint, query: { featureFlags } });
};

export const getSavedListings = (myRcaToken) => {
  const endpoint = savedListingsEntrypoint(myRcaToken);

  return request(endpoint);
};

export const getSavedSearches = (query, myRcaToken) => {
  const endpoint = getSavedSearchesEntryPoint(myRcaToken, query);

  return request(endpoint);
};

export const createEmailAlert = (query, myRcaToken) => {
  const endpoint = createEmailAlertEntryPoint(myRcaToken, query);

  return requestWithLogout(endpoint);
};

export const getMarketInsights = (queryParams, featureFlags) => {
  const endpoint = getMarketInsightsEntrypoint();
  return bffRequest({ ...endpoint, query: { ...queryParams, featureFlags } });
};

export const getMarketInsightsMulti = (marketInsightsMultiSearchQuery, featureFlags) => {
  const endpoint = getMarketInsightsEntrypointMulti(marketInsightsMultiSearchQuery);

  return bffRequest({ ...endpoint, query: { featureFlags } });
};

export const saveListing = (listingId, myRcaToken) =>
  requestWithLogout({
    path: 'save-listing',
    method: 'POST',
    header: myRcaHeaders(myRcaToken),
    body: {
      'listing-id': listingId,
    },
  });

export const deleteListing = (bookmarkId, myRcaToken) =>
  requestWithLogout({
    path: 'delete-listing',
    method: 'POST',
    header: myRcaHeaders(myRcaToken),
    body: {
      'bookmark-id': String(bookmarkId),
    },
  });
